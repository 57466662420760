export const planData = [
  {
    planId: 4,
    planTitle: "Plano Basic",
    planPrice: 87,
    benefitsIncluded: [
      "Biblioteca de Produtos",
      "Precificador de Produto",
      "Gerador de Copy",
      "Fornecedores", 
      
    ],
    benefitsNotIncluded: [
      "Produtos em alta",
      "Esteira de Produtos",
      "Curso de Dropshipping",
      "Gerente de Conta Exclusivo",
    ],
  },
  {
    planId: 5,
    planTitle: "Trimestral",
    planPrice: 67,
    benefitsIncluded: [
      "Biblioteca de Produtos",
      "Precificador de Produto",
      "Gerador de Copy",
      "Fornecedores", 
      "Produtos em alta",
      "Esteira de Produtos",
      "Curso de Dropshipping",

    ],
    benefitsNotIncluded: [
      "Gerente de Conta Exclusivo",
    ],
  },
  {
    planId: 9,
    planTitle: "Anual",
    planPrice: 57,
    benefitsIncluded: [
      "Biblioteca de Produtos",
      "Precificador de Produto",
      "Gerador de Copy",
      "Fornecedores", 
      "Produtos em alta",
      "Esteira de Produtos",
      "Curso de Dropshipping",
      "Gerente de Conta Exclusivo",
    ],
  },
];
